@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

// Override Bootstrap variables
$primary: #ff6347;
$secondary: #6c757d;
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #343a40;

$body-bg: #f5f7fb;
$font-family-base: "DM Sans", sans-serif;
$font-size-base: 1rem;

:root,
[data-bs-theme="light"] {
  --bs-border-color: rgba(216, 224, 237, 1);
  --bs-border-color-translucent: #d8e0ed;
  --bs-body-color: #0b0b0b;
  --bs-primary-rgb: 119, 73, 248;
  --bs-box-shadow: 0 0.5rem 1rem rgb(221 219 226);
  --bs-dropdown-link-active-bg: rgba(119, 73, 248, 1);
}
.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: rgba(119, 73, 248, 1);
  --bs-btn-border-color: rgba(119, 73, 248, 1);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0b5ed7;
  --bs-btn-hover-border-color: #0a58ca;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgba(119, 73, 248, 1);
  --bs-btn-active-border-color: rgba(119, 73, 248, 1) e;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: rgba(119, 73, 248, 1);
  --bs-btn-disabled-border-color: rgba(119, 73, 248, 1);
}

.form-check-input:checked {
  background-color: rgba(119, 73, 248, 1);
  border-color: rgba(119, 73, 248, 1);
}

.form-step {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.fade-in {
  opacity: 1 !important;
}

// Vertical Progress Bar Styles with Dot Steps
.progress-menu {
  position: sticky;
  top: 20px;
  margin-right: 20px;
  padding-left: 0;
  list-style: none;
}

.progress-step {
  position: relative;
  display: flex;
  align-items: center;
  padding: 15px 0;
  cursor: pointer;
}

.progress-step.active:not(:last-child)::before {
  background-color: #7749f8;
}

.progress-step:not(:last-child)::before {
  content: "";
  position: absolute;
  left: 11px;
  top: 32px;
  width: 3px;
  height: calc(100% - 0px);
  background-color: #7749f8;
}

.progress-dot {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #fff;
  border: #7749f8 3px solid;
  display: inline-block;
  margin-right: 15px;
  z-index: 1;
}

.progress-text {
  font-weight: bold;
  color: #7749f8;
}

.progress-step.active .progress-dot {
  background-color: #7749f8;
}

.progress-step.active .progress-text {
  color: #7749f8;
}

.nav-link,
.nav-link:focus,
.nav-link:hover {
  border: none !important;
  color: #0b0b0b;
  padding: 15px 0px;
  margin-right: 50px;
  font-size: 1.2rem;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active,
.nav-pills .nav-item.show .nav-link,
.nav-pills .nav-link.active {
  color: var(--bs-nav-tabs-link-active-color);
  background: none !important;
  border: none;
  border-bottom: #7749f8 2px solid !important;
  color: #7749f8;
  border-radius: 0px;
}

.js-pg-hg ul.nav-tabs li {
  padding: 0;
  margin: 0;
}
.js-pg-hg ul.nav-tabs {
  margin: 0;
  padding: 0;
}

.rc-slider-handle {
  border: solid 2px #7749f8;
}
.js-pg-hg ul {
  padding: 0;
  margin: 5px 0 0 0;
  li {
    margin: 0 0 5px 15px;
    color: #6d7178;
  }
}

.rc-slider-track,
.rc-slider-tracks {
  background-color: #8a62f9;
}

.menubar {
  top: 0;
  max-width: 400px;
  width: 100% !important;
}

.icon-clr {
  color: #bfbfbf;
  background: none;
  outline: 0 !important;
  padding-bottom: 0 !important;
  &:hover {
    color: rgba(119, 73, 248, 1);
  }
  &:active {
    border: none !important;
    color: #bfbfbf;
    outline: 0;
  }
}

.bm-menu ul li a.active,
.bm-menu ul li a.active svg,
.bm-menu ul li a:hover,
.bm-menu ul li a:hover svg {
  color: rgba(119, 73, 248, 1) !important;
}

.card-text {
  color: #6d7178;
}

.card-header {
  background: none;
  border: none;
}

.btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #16b296;
  --bs-btn-border-color: #16b296;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60, 153, 110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #16b296;
  --bs-btn-disabled-border-color: #16b296;
}

.btn-outline-primary {
  --bs-btn-color: rgba(119, 73, 248, 1);
  --bs-btn-border-color: rgba(119, 73, 248, 1);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgba(119, 73, 248, 1);
  --bs-btn-hover-border-color: rgba(119, 73, 248, 1);
  --bs-btn-focus-shadow-rgb: 13, 110, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgba(119, 73, 248, 1);
  --bs-btn-active-border-color: rgba(119, 73, 248, 1);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: rgba(119, 73, 248, 1);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: rgba(119, 73, 248, 1);
  --bs-gradient: none;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: rgba(119, 73, 248, 1);
  --bs-nav-link-hover-color: rgba(119, 73, 248, 1);
  --bs-nav-link-disabled-color: rgba(119, 73, 248, 1);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--bs-body-color);
}

.fixed-top {
  z-index: 99;
}
.error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.z-index-1 {
  z-index: 1;
}

.color-1 {
  background: rgba(245, 238, 255, 1);
}
.color-2 {
  background: rgba(255, 244, 241, 1);
}
.color-3 {
  background: rgba(231, 245, 255, 1);
}

.form-select {
  padding: 0.675rem 0.75rem;
}

.badge {
  font-size: 0.85rem;
  border: rgb(119, 73, 248) 2px solid;
  vertical-align: middle;
  font-weight: 400;
  color: rgba(119, 73, 248, 1);
}

.fr-toggle {
  min-width: 225px;
  background: #fff;
  width: 100%;
}

.dropdown-toggle {
  &::after {
    position: absolute;
    right: 25px;
    top: 28px;
  }
}

.jsc-nodata {
  svg {
    font-size: 50px;
  }
}

.table > tbody > tr > td,
.table > tbody > tr > th {
  vertical-align: middle;
}

.btn-link {
  --bs-btn-color: rgba(119, 73, 248, 1);
}

.table > :not(caption) > * > * {
  padding: 0.8rem 1rem;
}

.form-control {
  padding: 0.675rem 0.75rem;
  border: var(--bs-border-width) solid var(--bs-border-color);
}
label {
  color: rgba(109, 113, 120, 1);
}

.nav-bg {
  background: #f4f2fc;
}

h1 {
  letter-spacing: 0.5px;
}

h2 {
  letter-spacing: 1px;
}

.view-btn-cst {
  position: relative;
  left: -35px;
}

body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  background: #f5f7fb;
}

.js-pg-hg {
  min-height: calc(100vh - 124px);
  padding-top: 171px !important;
}

.js-pg {
  min-height: 100vh;
}

.react-datepicker {
  font-family: "DM SANS";
  font-size: 0.85rem;
  background-color: #fff;
  border: 1px solid #d8e0ed;
  border-radius: 1rem;

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 2.2rem;
    line-height: 2.3rem;
  }

  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover,
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    background-color: #7749f8;
  }

  .react-datepicker__day-names {
    white-space: nowrap;
    margin-bottom: -8px;
    margin-top: 10px;
    display: inline-block;
  }
  h2.react-datepicker__current-month {
    padding: 15px 0 0 0;
    margin: 0;
  }
  .react-datepicker__header {
    text-align: center;
    background-color: #d8e0ed57;
    border-bottom: 1px solid #d8e0ed;
    border-radius: 1rem 1rem 0rem 0rem !important;
    padding: 8px 0;
    position: relative;
  }
  .react-datepicker__navigation {
    top: 19px;
  }

  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle {
    fill: #d8e0ed;
    color: #d8e0ed;
  }
  .react-datepicker-popper .react-datepicker__triangle {
    stroke: #d8e0ed;
  }
}

.js-head-frm {
  button,
  a {
    &:first-child {
      border-radius: 30px 0px 0 30px;
    }
    &.rounded-end {
      border-radius: 0px 30px 30px 0px !important;
      line-height: 45px;
    }
  }
  input.form-control.rounded-start {
    border-radius: 30px 0px 0px 30px !important;
    border-left: 0px;
    min-width: 300px;
    padding: 0 0 0 30px;
  }
}

html {
  scroll-behavior: smooth;
}

.js-pg-hg ul.timeline {
  list-style: none;
  padding: 20px 0 0px;
  position: relative;
}

.timeline:before {
  top: 25px;
  bottom: 98px;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: #7749f8;
  left: 21px;
  margin-left: -1.5px;
}

.timeline > li > .timeline-badge span {
  background: #fff;
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 100%;
  vertical-align: middle;
  position: relative;
  top: -2px;
}

.timeline > li {
  padding-bottom: 30px;
  position: relative;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li > .timeline-panel {
  width: 93%;
  float: left;
  border: none;
  border-radius: 0;
  padding: 0;
  position: relative;
  box-shadow: none;
}

.timeline > li > .timeline-badge {
  color: #fff;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 5px;
  left: 21px;
  margin-left: -25px;
  background-color: #7749f8;
  z-index: 9;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.timeline > li.timeline-inverted > .timeline-panel {
  float: right;
}

.timeline > li.timeline-inverted > .timeline-panel:before {
  border-left-width: 0;
  border-right-width: 15px;
  left: -15px;
  right: auto;
}

.timeline > li.timeline-inverted > .timeline-panel:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}

.timeline-heading {
  width: 100%;
}
.timeline-title {
  margin-top: 0;
  color: inherit;
}

.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0;
}

.timeline-body > p + p {
  margin-top: 5px;
}

.accordion-header {
  margin-bottom: 0;
  background: #f4f2fc;
}

.accordion-body {
  padding: 30px;
}

.accordion-button {
  background: none !important;
  font-size: 1.1rem;
}

.accordion-button:not(.collapsed) {
  background: #b9a4f3 !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .chatbox-container {
    right: 0%;
    max-width: 100%;
  }

  .navbar-toggler {
    position: absolute;
    right: 30px;
    top: 30px;
  }

  .navbar-collapse {
    background: #fff;
    padding: 15px 30px;
  }
  .navbar-collapse .nav-item button {
    display: block;
    margin: 0 0 15px 0 !important;
    width: 100%;
  }
  #navbarSupportedContent ul li {
    margin-bottom: 15px;
    width: 100%;
    display: block;
  }

  .timeline:before,
  .timeline > li > .timeline-badge {
    left: 6px;
  }

  .js-profile {
    .js-pg-hg ul.nav-pills li {
      width: 100%;
    }

    .nav-link,
    .nav-link:focus,
    .nav-link:hover {
      margin-right: 0;
    }
  }

  .nav-link,
  .nav-link:focus,
  .nav-link:hover {
    font-size: 1rem;
  }

  .page-link {
    position: relative;
    display: block;
    padding: 15px;
    font-size: 15px;
  }

  .js-pst {
    .form-check {
      position: relative;
      margin-top: -10px;
      margin-bottom: 10px;
    }
  }

  .js-pst img {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .js-pg-hg ul.pagination li {
    margin: 0 0 5px 0px;
    color: #6d7178;
  }

  .js-select {
    width: 50%;
    display: inline-block;
    vertical-align: sub;
    .fr-toggle {
      min-width: 100%;
      font-size: inherit;
    }
  }
  .js-select-add {
    display: inline-block;
    width: 80px;
  }

  .fs-5,
  .h5,
  h5 {
    font-size: 1rem !important;
  }

  .js-head-frm input.form-control.rounded-start {
    font-size: 14px;
    min-width: inherit;
    height: 53px;
  }

  .js-pg-hg {
    padding: 0 15px;
    width: 100%;
  }
  .js-head-frm {
    width: 100%;
    padding: 0 3%;
  }

  .js-head-frm button.rounded-end,
  .js-head-frm a.rounded-end {
    border-radius: 0px 30px 30px 0px !important;
    line-height: 40px;
    font-size: 13px;
  }

  .fr-toggle {
    min-width: 153px;
    font-size: 13px;
  }

  .d-flex.js-lg-link {
    margin: 18px 0 0 0px;
    width: 100%;
    justify-content: center;
  }
  .icon-clr {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .js-pg-hg {
    min-height: calc(100vh - 98px);
    padding-top: 260px !important;
  }
  .navbar-brand {
    margin-bottom: 15px;
    margin: 0 0 15px 15px;
    width: 100%;
    text-align: center;
  }
  .bsb-pricing-2 {
    padding: 0 30px;

    .shadow-sm,
    .shadow-lg {
      box-shadow: var(--bs-box-shadow-sm) !important;
    }
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .h5,
  h5 {
    font-size: 1rem;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .d-flex.js-lg-link {
    margin: 18px 0 0 0px;
    width: 100%;
    justify-content: center;
  }

  .js-head-frm {
    width:40%;
  }

  .navbar-brand {
    padding-bottom: 15px;
  }

  .js-pg-hg {
    min-height: calc(100vh - 76px);
    padding-top: 275px !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .d-flex.js-lg-link {
    margin: 0px 0 0 30px;
    width: 100%;
    justify-content: inherit;
  }
  .js-head-frm input.form-control.rounded-start {
    min-width: 200px;
  }
  .js-pg-hg {
    min-height: calc(100vh - 76px);
    padding-top: 150px !important;
  }
  .fr-toggle {
    min-width: 175px;
    background: #fff;
  }
  .nav-link,
  .nav-link:focus,
  .nav-link:hover {
    margin-right: 25px;
    font-size: 1.1rem;
    padding: 0 15px 15px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .js-head-frm input.form-control.rounded-start {
    min-width: 300px;
  }
  .js-pg-hg {
    min-height: calc(100vh - 125px);
    padding-top: 171px !important;
  }
  .fr-toggle {
    min-width: 200px;
    background: #fff;
  }
  .d-flex.js-lg-link {
    margin: 0px 0 0 30px;
    width: auto;
    justify-content: inherit;
  }
}

  ul.js-comment {
    list-style:none;
    padding:0;
    margin:0;
    li {
      list-style:none;
      padding:0;
      margin:0;
    }
  }

  ul.js-comment:before {
    bottom: 195px;
  }